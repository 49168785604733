import React, { useState } from "react";
import "../assets/css/NewsLetter.css"; // Import CSS file for styling
import Button from "./Button";
import { Formik, Field, ErrorMessage, Form as FormikForm } from "formik";
import * as Yup from "yup";
import { Form, Container, Row, Col } from "react-bootstrap";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const excludedEmailDomains = [
  "gmail.com",
  "yahoo.com",
  "yahoomail.com",
  "outlook.com",
  "aol.com",
  "protonmail.com",
  "zohomail.com",
  "gmx.com",
  "icloud.com",
  "yandex.com",
  "comcast.com",
];

const isOfficialEmail = (email) => {
  const domain = email.split("@")[1];
  return !excludedEmailDomains.includes(domain);
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email format")
    .test("is-official", "Please use an official email", (value) =>
      isOfficialEmail(value)
    )
    .required("Business Email is required"),
});

const Newsletter = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [formSubmitted, setFormSubmitted] = useState(false);

  const initialValues = {
    email: "",
    formType: "newsletterForm",
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      console.log("Sending email with fetch request");
      const apiUrl = `${process.env.REACT_APP_NEWSLETTER_API_URL}/send-email`;
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_X_API_KEY,
        },
        body: JSON.stringify({
          to: ['karthikeyan.s@idexcel.com', 'rajkumar.g@idexcel.com', 'neel.a@idexcel.com'], // Receiver's email address
          text: `Newsletter Request: ${values.email}!`,
        }),
      });
  
      // console.log("Response received from fetch request:", response);
  
      if (response.ok) {
        console.log("Email sent successfully");
        setFormSubmitted(true);
        resetForm();
      } else {
        const errorDetails = await response.json();
        console.error("Error sending email:", errorDetails);
      }
    } catch (error) {
      console.error("Error during email submission process:", error);
    }
  };
  
  return (
    <section className="newsletter-section">
      <div className="left-section">
        <h2 style={{ color: "white", fontSize: "34px", fontWeight: "400" }}>
          Stay in Cync with the latest news!
        </h2>
      </div>
      <div className="right-section">
        {!formSubmitted ? (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, handleChange, handleSubmit }) => (
              <Form onSubmit={handleSubmit} className="subscription-form">
                <input
                  className="form-control contact-input"
                  type="email"
                  name="email"
                  placeholder="Business Email*"
                  value={values.email}
                  onChange={handleChange}
                  required
                />
                <input type="hidden" name="formType" value="newsletterForm" />
                <Button size="small" color="primary" type="submit">
                  Subscribe Now
                </Button>
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-danger"
                />
              </Form>
            )}
          </Formik>
        ) : (
          <div className="thank-you-message">
            <h2 style={{ color: "white" }}>Thank You!</h2>
            <p style={{ color: "white" }}>
              Your message has been sent successfully. We will get back to you
              soon.
            </p>
          </div>
        )}
      </div>
    </section>
  );
};

export default Newsletter;
