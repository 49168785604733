// Array of valid slugs based on your markdown file names
const validSlugs = [
  "spreading-automation-and-machine-learning-in-data-standardization",
  "revolutionizing-financial-spreading-the-impact-of-automation-and-ai",
  "revolutionizing-financial-factoring",
  "simplifying-compliance-for-asset-based-lending",
  "modernizing-underwriting-with-an-Agile-Approach",
  "spreading-the-analysis-complex",
  "automating-loan-origination-processes",
  "locating-rent-roll-tenants-with-enterprise-search-is-now-possible",
  "innovative-lending-requires-innovative-solutions",
  "factoring-automated-invoice-verification-for-cost-optimization",
  "dual-factor-risk-assessment-a-new-standard-in-loan-evaluation",
  "reporting-as-a-service-partnering-for-premium-insights",
  "automate-bbcs-to-mitigate-risk",
  "factoring-solving-problems-through-automation",
  "analyzing-the-benefits-of-self-service-administration-for-loan-origination-software",
  "cync-asset-based-lending-abl",
  "cync-spreadings",
]; // Replace with your actual markdown file names

export default validSlugs;
