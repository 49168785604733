import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { Row, Col, Container, Card } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import remarkHtml from "remark-html";
import insightsData from "../../Data/InsightsData";
import "../../assets/css/PostDetails.css";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import rehypeRaw from 'rehype-raw';

const handleNavigation = (url) => {
  window.location.assign(url);
};

const PostDetail = () => {
  const { slug } = useParams();
  const [content, setContent] = useState(null);
  const [post, setPost] = useState(null);
  const [postTitle, setPostTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");

  useEffect(() => {
    const currentPost = insightsData.find((p) => p.slug === slug);
    if (currentPost) {
      setPost(currentPost);
      setPostTitle(currentPost.title);
      import(`../insight/${currentPost.filename}`)
        .then((res) => fetch(res.default))
        .then((res) => res.text())
        .then((text) => {
          setContent(text);
          const sentences = text.split(". ");
          if (sentences.length > 0) {
            setMetaDescription(sentences[0] + ".");
          }
        });
    }
  }, [slug]);

  if (!post || !content) return;

  const relatedPosts = post.tags
    ? insightsData
        .filter(
          (p) =>
            p.slug !== post.slug &&
            p.tags &&
            p.tags.some((tag) => post.tags.includes(tag))
        )
        .slice(0, 3)
    : [];

  return (
    <div>
      <Helmet>
        <title>{postTitle} - CyncSoftware | Blog</title>
        <meta name="description" content={metaDescription} />
      </Helmet>
      <div
        className="post-banner"
        style={{
          backgroundImage: `url(/images/${post.backgroundImage})`,
        }}
      >
        <Row className="h-100 align-items-center">
          <Col lg={8}>
            <div className="post-banner-content h-100 align-items-center">
              <h1>{post.title}</h1>
              {/* {post.tags && (
              <div className="post-tags">
                {post.tags.map((tag) => (
                  <Link
                    key={tag}
                    to={`/tag/${tag}`}
                    onClick={() => handleNavigation(`/tag/${tag}`)}
                  >
                    <span className="tag">{tag}</span>
                  </Link>
                ))}
              </div>
            )} */}
            </div>
          </Col>
        </Row>
      </div>
      <div className="post-content">
        <Row>
          <Col lg={12}>
            <ReactMarkdown remarkPlugins={[remarkHtml]} rehypePlugins={[rehypeRaw]}>
              {content}
            </ReactMarkdown>
            <br />
            {post.keywords && (
              <div className="post-keywords">
                {post.keywords.map((keyword) => (
                  <Link
                    key={keyword}
                    to={`/keywords/${keyword}`}
                    onClick={() => handleNavigation(`/keywords/${keyword}`)}
                  >
                    <span className="keyword">{keyword}</span>
                  </Link>
                ))}
              </div>
            )}
          </Col>
        </Row>
      </div>
      {relatedPosts.length > 0 && (
        <div className="related-posts">
          <Container>
            <h1>Related Posts</h1>
            <div className="space"></div>
            <Row xs={1} md={3} className="g-4">
              {relatedPosts.map((insight) => (
                <Col key={insight.id}>
                  <Card className="h-100">
                    <p className="date">{insight.date}</p>
                    <Card.Img
                      variant="top"
                      src={`/images/${insight.imageName}`}
                      alt={`Insight ${insight.id}`}
                    />
                    <Card.Body className="insightsbody">
                      <div className="tags">
                        {insight.tags.map((tag, index) => (
                          <span key={tag}>
                            <Link
                              to={`/tag/${tag}`}
                              className="tag-link boxtags"
                              onClick={() => handleNavigation(`/tag/${tag}`)}
                            >
                              {tag}
                            </Link>
                            {index < insight.tags.length - 1 && (
                              <span> | </span>
                            )}
                          </span>
                        ))}
                      </div>
                      <Link
                        to={insight.buttonLink}
                        onClick={() => handleNavigation(insight.buttonLink)}
                      >
                        <Card.Title>{insight.title}</Card.Title>
                      </Link>
                    </Card.Body>
                    <Link
                      to={insight.buttonLink}
                      onClick={() => handleNavigation(insight.buttonLink)}
                      className="read-more"
                    >
                      {insight.buttonLabel}
                      <FontAwesomeIcon
                        icon={faArrowRight}
                        style={{ margin: "5px", float: "right" }}
                      />
                    </Link>
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
        </div>
      )}
      <div className="space"></div>
    </div>
  );
};

export default PostDetail;
