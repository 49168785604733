export const RoutePaths = {
    Home:"/",
    Company: "/company",
    CyncTeam: "/cync-team",
    Products: "/products",
    Careers: "/careers-culture",
    CyncAbl:"/cync-abl",
    CyncFactoring: "/cync-factoring",
    CyncLos: "/cync-loan-origination-system-los",
    CyncSpreading: "/cync-spreading",
    Insights: "/insights",
    NewsEvents: "/news-events",
    RequestDemo: "/request-a-demo",
    Security: "/security",
    Contact: "/contact",
    PrivacyPolicy: "/privacy-policy",
    Report:"/cync-reporting-hotline",
    DisclosurePolicy:"/responsible-disclosure-policy",

}