import React from "react";
import "../../assets/css/SyndicatedStepBoxes.css";

const SyndicatedStepBoxes = () => {
  return (
    <div className="roadmap-container">
      <div className="roadmap-box left-box box1 syndicatebox1">
        <h2 style={{color:'#2971EB'}}>Effortlessly Manage Complex Syndicated and Participation Loans</h2>
        <p>
          No matter the complexity of your loan terms, manage diverse structures
          and requirements automatically with Cync Syndicated Lending.
          Effortlessly handle both syndicated and participation loans.
          Additionally, assign different interest rates, fees, maturities, or
          covenants to each participant allocation, addressing specific
          financing needs and varied risk appetites.
        </p>
        <div className="arrow left-arrow"></div>
      </div>
      <div className="roadmap-box right-box box2 syndicatebox2">
        <h2 style={{color:'#2971EB'}}>
          Provide Multiple Allocation Methods and Flexible Drawdown Options
        </h2>
        <p>
          Prioritize participant allocations through a percentage-based method
          or opt for a flexible, responsive distribution using a dynamic
          continuous priority system. Additionally, empower borrowers to manage
          their cash flows effectively by offering full drawdowns, scheduled
          drawdowns, bridge drawdowns, revolving credit facilities, or
          discretionary drawdowns tied to specific events or milestones.
        </p>
        <div className="arrow right-arrow"></div>
      </div>
      <div className="roadmap-box left-box box3 syndicatebox3">
        <h2 style={{color:'#2971EB'}}>Streamline Participant Restructuring</h2>
        <p>
          Enhance the viability and risk management of syndicated loans by
          automating lender composition and loan term adjustments, eliminating
          the need for spreadsheets. Efficiently handle changes when lenders
          reduce their exposure, exit, or new lenders join the syndicate.
          Facilitate seamless loan term adjustments, including interest rates,
          repayment schedules, and covenants, to accommodate changes in the
          syndicate.
        </p>
        <div className="arrow left-arrow"></div>
      </div>
      <div className="roadmap-box right-box box4 syndicatebox4">
        <h2 style={{color:'#2971EB'}}>Improve Productivity With Advanced Automation</h2>
        <p>
          Leverage automation to streamline fund allocations and repayments,
          ensure compliance with covenants, and conduct real-time risk
          assessments. Seamlessly manage drawdowns and loan modifications.
          Automate document management with efficient metadata tagging.
          Additionally, enhance participant communication through automated
          updates and notifications.
        </p>
        <div className="arrow right-arrow"></div>
      </div>
      <div className="roadmap-box left-box box5 syndicatebox5">
        <h2 style={{color:'#2971EB'}}>
          Monitor Every Kind of Collateral, Including Marketable Securities
        </h2>
        <p>
          Evaluate a wide range of collateral, including accounts receivable,
          inventory, real estate, equipment, government contracts (GovCon), and
          agricultural assets. Employ automation to expedite Borrowing Base
          Certificate (BBC) generation. Additionally, communicate collateral
          valuations to each participant, proportionate to their participation
          ratio, to ensure transparency.
        </p>
        <div className="arrow left-arrow"></div>
      </div>

      {/* Vertical connecting line */}
      <div className="vertical-line"></div>
    </div>
  );
};

export default SyndicatedStepBoxes;
