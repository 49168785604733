import React from "react";
import "../../assets/css/home.css";
import "../../assets/css/Company.css";
import { Container, Row, Col, Card } from "react-bootstrap";
import HrLines from "../designs/HrLines";
import Button from "../Button";
import { CompanyData } from "../../Data/boxesData.js";
import reverse from "../../assets/icons/reverse.png";
import { Helmet } from "react-helmet";

const flipBox = (id) => {
  const clickedBox = document.getElementById(`box-${id}`);
  const allBoxes = document.querySelectorAll(".box");

  // Remove 'flipped' class from all boxes except the clicked one
  allBoxes.forEach((box) => {
    if (box !== clickedBox) {
      box.classList.remove("flipped");
    }
  });

  // Toggle 'flipped' class for the clicked box
  clickedBox.classList.toggle("flipped");
};

const Company = () => {
  return (
    <div>
      <Helmet>
        <title>
          The AI-Powered Lending Automation Company - Cync Software®
        </title>
        <meta
          name="description"
          content="At Cync Software, we integrate AI and advanced cloud technologies, leveraging best practices to enhance lending automation, streamline data capture, and optimize decision-making processes for lenders."
        />
      </Helmet>

      <section className="company-sections">
        <Container className="h-100">
          <Row className="h-100 align-items-center">
            <Col lg={9}>
              <div className="content">
                <h1>About Us</h1>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Container>
        <Row>
          <Col lg={12}>
            <h1 className="mb-4" style={{ textAlign: "center" }}>
              Cync Software's Mission Statement
            </h1>
            <HrLines />
            <p style={{ marginTop: "20px", fontSize: "20px" }}>
              Cync Software is committed to 
              <b>“Supercharging Your Lending Automation”</b> by seamlessly
              integrating artificial intelligence & cutting-edge cloud
              technologies with premier solutions based on best practices. Our
              dedication to revolutionizing the lending processes with research
              and user-centric design transforms the way data is captured and
              decisions are made—launching a new era of efficiency and accurate
              decision making for lenders.
            </p>
          </Col>
        </Row>
      </Container>

      <section>
        <Container>
          <h1 className="privacyheading">Our Core Values</h1>
          <HrLines />
          <div className="space"></div>
          <Row className="box-row">
            {CompanyData.map((box) => (
              <Col key={box.id} md={3} sm={6} xs={12} className="box-col">
                <Card
                  className="box"
                  id={`box-${box.id}`}
                  onClick={() => flipBox(box.id)}
                >
                  <div className="front">
                    <div className="space"></div>
                    <div className="icon-top-right">
                      <img src={reverse} alt="Extra Icon" />
                    </div>
                    <img src={box.icon} alt={box.title} />
                    <br />
                    <h3>{box.title}</h3>
                  </div>
                  <div className="back">
                    <p>{box.description}</p>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>

      <div className="space"></div>
    </div>
  );
};

export default Company;
