import React, { useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import "../../assets/css/insights.css";
import Newsletter from "../NewsLetter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import insightsData from "../../Data/InsightsData";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { isValidPath } from "../../utils/validation";
// Sample insights data

const handleNavigation = (url) => {
  if (isValidPath(url)) {
    window.location.assign(url);
    window.location.reload();
  } else {
    console.warn("Invalid path:", url);
  }
};
const sortedInsightsData = [...insightsData].sort(
  (a, b) => new Date(b.date) - new Date(a.date)
);

const Insights = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 6;

  // Logic to calculate which posts to display based on current page
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = sortedInsightsData.slice(
    indexOfFirstPost,
    indexOfLastPost
  );

  // Change page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(1, 600); // Scroll to the top of the page
  };

  return (
    <div>
      <Helmet>
        <title>
          Insights on AI-Powered Lending Automation - Cync Software®
        </title>
        <meta
          name="description"
          content="Explore our latest insights on commercial lending, loan origination, factoring, spreading, and financing solutions."
        />
      </Helmet>
      <section className="insights-sections">
        <Container className="h-100">
          <Row className="h-100 align-items-center">
            <Col lg={9}>
              <div className="content">
                <h1>Insights</h1>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <div className="space"></div>

      {/* <section>
        <Container>
          <p style={{ fontSize: "26px" }}>
            Stay informed with our latest articles on commercial lending, loan
            origination systems, factoring, spreading, and innovative financing
            solutions. Our expert insights help you navigate the evolving
            lending landscape, understand market dynamics, and make informed
            decisions. Don't miss out on the crucial updates shaping the future
            of commercial lending.
          </p>
        </Container>
      </section>
      <div className="space"></div> */}

      <Newsletter />
      <div className="space"></div>

      <Container>
        <Row xs={1} md={3} className="g-4">
          {currentPosts.map((insight) => (
            <Col key={insight.id}>
              <Card className="h-100">
                <p className="date">{insight.date}</p>
                <Card.Img
                  variant="top"
                  src={`/images/${insight.imageName}`}
                  alt={`Insight ${insight.id}`}
                />
                <Card.Body className="insightsbody">
                  <div className="tags">
                    {insight.tags.map((tag, index) => (
                      <span key={tag}>
                        <Link to={`/tag/${tag}`} className="tag-link boxtags">
                          {tag}
                        </Link>
                        {index < insight.tags.length - 1 && <span> | </span>}
                      </span>
                    ))}
                  </div>
                  <Link
                    to={insight.buttonLink}
                    onClick={() => handleNavigation(insight.buttonLink)}
                  >
                    <Card.Title>{insight.title}</Card.Title>
                  </Link>
                </Card.Body>
                <Link
                  to={insight.buttonLink}
                  onClick={() => handleNavigation(insight.buttonLink)}
                  className="read-more"
                >
                  {insight.buttonLabel}
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    style={{ margin: "5px", float: "right" }}
                  />
                </Link>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
      <div className="space"></div>

      {/* Pagination */}
      <Container>
        <Row>
          <Col className="d-flex justify-content-center">
            <nav>
              <ul className="pagination">
                {Array.from(
                  { length: Math.ceil(insightsData.length / postsPerPage) },
                  (_, i) => (
                    <li
                      key={i}
                      className={`page-item ${
                        currentPage === i + 1 ? "active" : ""
                      }`}
                    >
                      <button
                        onClick={() => paginate(i + 1)}
                        className="page-link"
                      >
                        {i + 1}
                      </button>
                    </li>
                  )
                )}
              </ul>
            </nav>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Insights;
