const testimonials = [
    {
      image: '/background/testimonialbg.png',
      heading: 'Customer Testimonials',
      text: '“The upload to the BBC calculation went from 4 hours manually per client to 18 minutes with Cync—this was a huge efficiency gain for us. Also, getting information through to clients has gone from 10 days per month for turn days down to 3-senior management has been very happy with these results.”',
      author: '- Errin Richardson, Columbia Bank',
      videoUrl: 'https://player.vimeo.com/video/248446150?color&autopause=0&loop=0&muted=0&title=1&portrait=1&byline=1#t=',
    },
    {
      image: '/background/testimonialbg2.png',
      heading: 'Customer Testimonials',
      text: '“Before Cync Software, our loan processing was entirely manual and Excel-based. We needed a cloud-based solution that would reduce the potential for error, improve the user experience, and provide the automation we needed. Cync Software really went above and beyond by allowing us to customize a solution to better fit our growing portfolio.”',
      author: '- Evan Henris, Parabilis',
      videoUrl: 'https://player.vimeo.com/video/492186903?color&autopause=0&loop=0&muted=0&title=1&portrait=1&byline=1#t=',
    },
    {
      image: '/background/testimonialbg3.png',
      heading: 'Customer Testimonials',
      text: '“Cync created a customized solution for us, that reduced our costs from having an external vendor manage our loans to having it all in one application. We can service more loans with the same amount of staff.”',
      author: '- Jon Finley, CircleUp',
      videoUrl: 'https://player.vimeo.com/video/518242397?color&autopause=0&loop=0&muted=0&title=1&portrait=1&byline=1#t=',
    },
  ];

  export default testimonials;