import React from "react";
import "../../assets/css/LosStepBoxes.css";

const LosStepBoxes = () => {
  return (
    <div className="roadmap-container">
      <div className="roadmap-box left-box box1">
        <h2 style={{color:'#2971EB'}}>High Speed-To-Market with No-Code Self-Service Administration</h2>
        <p>
          Want to launch a new loan product to expand your market? Want to
          modify your processes to lend faster or to accommodate regulatory
          changes? With Cync LOS’s codeless template creation, reflect business
          and policy decisions in your loan origination process for any kind of
          loan yourself without having to engage professional services.
        </p>
        <div className="arrow left-arrow"></div>
      </div>
      <div className="roadmap-box right-box box2">
        <h2 style={{color:'#2971EB'}}>Customize Your Solution with Microservices Library</h2>
        <p>
          Build a bespoke solution that meets your unique business needs by
          choosing relevant microservices from Cync LOS’s extensive
          microservices library. Optimize your loan origination process for
          agility and efficiency while paying only for the features and
          functionality you require.
        </p>
        <div className="arrow right-arrow"></div>
      </div>
      <div className="roadmap-box left-box box3">
        <h2 style={{color:'#2971EB'}}>Built for Scale and Innovation</h2>
        <p>
          Scale your lending on-demand with guaranteed high availability through
          a cloud-based deployment, while also eliminating the need for upfront
          capital expenditure. Embrace open banking with Cync LOS’s API-first
          approach to seamlessly integrate with your tech ecosystem and consume
          off-the-shelf third-party APIs to drive innovation.
        </p>
        <div className="arrow left-arrow"></div>
      </div>
      <div className="roadmap-box right-box box4">
        <h2 style={{color:'#2971EB'}}>Improve Staff Productivity with Automation</h2>
        <p>
          Take the focus off mundane, repetitive tasks by allowing Cync LOS’s
          automation to take over. Stop spending hours and instead have
          top-notch artificial intelligence rapidly churn out GAAP-compliant
          spreads for analysis. Or automatically track relationship- and
          loan-specific covenants and ticklers, allowing loan officers and
          compliance analysts to focus solely on verifying covenant evaluations.
        </p>
        <div className="arrow right-arrow"></div>
      </div>
      <div className="roadmap-box left-box box5">
        <h2 style={{color:'#2971EB'}}>Custom Dashboards with Real-Time Analytics</h2>
        <p>
          Put your team first by customizing dashboard layouts for every role –
          credit analysts, portfolio managers, or anyone else! With a choice of
          over four dozen data visualizations and reports, look at data from
          every possible angle. Provide actionable insights in real time to
          enable proactive decision-making via delightfully simple user
          interfaces.
        </p>
        <div className="arrow left-arrow"></div>
      </div>

      {/* Vertical connecting line */}
      <div className="vertical-line"></div>
    </div>
  );
};

export default LosStepBoxes;
