import React from "react";
import "../../../assets/css/home.css";
import "../../../assets/css/CyncTeam.css";
import { Container, Row, Col, Card } from "react-bootstrap";
import { useState } from "react";
import { Helmet } from "react-helmet";

const GenaCalender = () => {
  const [flippedId, setFlippedId] = useState(null);

  const teamMembers = [
    {
      id: 1,
      name: "Gena VanMeter",
      jobTitle: "Account Executive",
      imageUrl: "/team/gena.jpg",
      description:
        "Gena is a native of Texas and graduated from North Texas University. Gena joins Cync after 5+ years as a Senior Sales Development Representative in the Financial Industry. She has acquired valuable experience with loan origination, helping financial institutions foster a seamless lending experience while building lasting relationships. She has a passion for music, movies, ministry, and helping others develop their talent and purpose.",
    },
  ];
  const handleFlip = (id) => {
    setFlippedId(flippedId === id ? null : id);
  };

  return (
    <div>
      <Helmet>
        <title>Gena VanMeter Calendar - Cync Software®</title>
        <meta
          name="description"
          content="Get to know our dedicated team driving innovation and excellence in lending solutions and technology."
        />
         <meta name="robots" content="noindex" />
      </Helmet>

      <div className="space"></div>
      <div className="space"></div>
      <div className="space"></div>

      <Container style={{height:'auto'}}>
        <Row>
          <Col lg={5}>
          <h1>Let's Start a Conversation!</h1><br/>
          <p>Ready to supercharge your lending with Cync Software?
          Schedule a meeting with Gena VanMeter using the calendar. </p>
            <Row xs={1} md={2} className="g-3">
              {teamMembers.map((member) => (
                <Col key={member.id} className="teamcol">
                  <Card
                    className={`team-member ${
                      flippedId === member.id ? "flipped" : ""
                    }`}
                    onClick={() => handleFlip(member.id)}
                  >
                    <div className="member-image">
                      {flippedId !== member.id && (
                        <span
                          className="plus-sign"
                          onClick={() => handleFlip(member.id)}
                        >
                          +
                        </span>
                      )}
                      {flippedId !== member.id && (
                        <Card.Img
                          variant="top"
                          src={member.imageUrl}
                          alt={member.name}
                        />
                      )}
                    </div>
                    <Card.Body
                      className={
                        flippedId === member.id ? "back-description" : ""
                      }
                    >
                      <Card.Title>
                        {flippedId !== member.id ? member.name : null}
                      </Card.Title>
                      <Card.Text>
                        {flippedId !== member.id ? member.jobTitle : null}
                      </Card.Text>
                      {flippedId === member.id && (
                        <div className="member-description" style={{height:'410px', fontSize: '16px'}}>
                          {member.description}
                        </div>
                      )}
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>
          <Col lg={7}>
          <iframe src="https://meetings.salesloft.com/cyncsoftware/genavanmeter" height="670" width="100%"></iframe>
          </Col>
        </Row>
      </Container>

      
      <div className="space"></div>
      <div className="space"></div>
    </div>
  );
};

export default GenaCalender;
