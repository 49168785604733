import React from "react";
import { useParams, Link } from "react-router-dom";
import { Container, Row, Col, Card } from "react-bootstrap";
import insightsData from "../../Data/InsightsData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import "../../assets/css/TagPage.css";
import { Helmet } from "react-helmet";

const TagPage = () => {
  const { tag } = useParams();
  const taggedPosts = insightsData
    .filter((post) => post.tags.includes(tag))
    .sort((a, b) => new Date(b.date) - new Date(a.date));

  const handleNavigation = (url) => {
    window.location.assign(url);
  };

  return (
    <div>
      <div className="space"></div>
      <div className="space"></div>
      <div className="space"></div>
      <Helmet>
        <title>{tag} - CyncSoftware | Blog</title>
      </Helmet>

      <Container>
        <h1>Posts tagged with "{tag}"</h1>
        <div className="space"></div>
        <Row xs={1} md={3} className="g-4">
          {taggedPosts.map((insight) => (
            <Col key={insight.slug}>
              <Card className="h-100">
                <p className="date">{insight.date}</p>
                <Card.Img
                  variant="top"
                  src={`/images/${insight.imageName}`}
                  alt={`Insight ${insight.slug}`}
                />
                <Card.Body className="insightsbody">
                  <div className="tags">
                    {insight.tags.map((tag, index) => (
                      <span key={tag}>
                        <Link
                          to={`/tag/${tag}`}
                          className="tag-link boxtags"
                          onClick={() => handleNavigation(`/tag/${tag}`)}
                        >
                          {tag}
                        </Link>
                        {index < insight.tags.length - 1 && <span> | </span>}
                      </span>
                    ))}
                  </div>
                  <Link
                    to={`/insights/${insight.slug}`}
                    onClick={() =>
                      handleNavigation(`/insights/${insight.slug}`)
                    }
                  >
                    <Card.Title>{insight.title}</Card.Title>
                  </Link>
                </Card.Body>
                <Link
                  to={`/insights/${insight.slug}`}
                  onClick={() => handleNavigation(`/insights/${insight.slug}`)}
                  className="read-more"
                >
                  Read More
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    style={{ margin: "5px", float: "right" }}
                  />
                </Link>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
      <div className="space"></div>
    </div>
  );
};

export default TagPage;
