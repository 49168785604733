import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap"; // Assuming you're using Bootstrap for styling
import rma from "../../assets/banner/rma.jpg";
import "./RMA.css";
import HrLines from "../designs/HrLines";
import rmaimage from "../../assets/images/rmaimage.png";
import { Helmet } from "react-helmet";
import { Formik, Field, ErrorMessage, Form as FormikForm } from "formik";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import emailjs from "emailjs-com";
import { useNavigate } from "react-router-dom";

// Custom validation function to check for official email domains
const excludedEmailDomains = [
  "gmail.com",
  "yahoo.com",
  "yahoomail.com",
  "outlook.com",
  "aol.com",
  "protonmail.com",
  "zohomail.com",
  "gmx.com",
  "icloud.com",
  "yandex.com",
  "comcast.com",
]; // Add your excluded domains here
const isOfficialEmail = (email) => {
  const domain = email.split("@")[1];
  return !excludedEmailDomains.includes(domain);
};

// Validation Schema
const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(/^[^<>]+$/, "Invalid input")
    .required("First Name is Required"),
  lastName: Yup.string()
    .matches(/^[^<>]+$/, "Invalid input")
    .required("Last Name is Required"),
  company: Yup.string()
    .matches(/^[^<>]+$/, "Invalid input")
    .required("Company Name is Required"),
  jobTitle: Yup.string()
    .matches(/^[^<>]+$/, "Invalid input")
    .required("Job Title is Required"),
  email: Yup.string()
    .email("Invalid email format")
    .test("is-official", "Please use an official email", (value) =>
      isOfficialEmail(value)
    )
    .required("Business Email is required"),
  phone: Yup.string().matches(
    /^[0-9,()-]+$/,
    "Phone number must contain only digits"
  ),
  products: Yup.string()
    .matches(/^[^<>]+$/, "Invalid input")
    .required("Please Select any one of the products"),

  agree: Yup.boolean().oneOf(
    [true],
    "You must accept the terms and conditions"
  ),
});

// Team members data
const teamMembers = [
  //   {
  //     name: "Lydia Taylor",
  //     imageUrl: "/team/lydia.jpeg",
  //   },
  {
    name: "Justin Reimers",
    imageUrl: "/team/justin.jpg",
  },
  //   {
  //     name: "Gena VanMeter",
  //     imageUrl: "/team/gana.jpg",
  //   },
];

const Forum = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);

  const initialValues = {
    firstName: "",
    lastName: "",
    company: "",
    jobTitle: "",
    email: "",
    phone: "",
    products: "",
    message: "",
    agree: false,
  };

  const navigate = useNavigate();
  const handleSubmit = async (values, { resetForm }) => {
    emailjs
      .send(
        "service_hvnra4e", // Replace with your Service ID
        "template_77xovtf", // Replace with your Template ID
        {
          firstName: values.firstName,
          lastName: values.lastName,
          company: values.company,
          jobTitle: values.jobTitle,
          email: values.email,
          phone: values.phone,
          products: values.products,
          message: values.message,
        },
        "PbNlXqv_F8t_Msgoe" // Replace with your User ID
      )
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
        setFormSubmitted(true);
        navigate("/request-a-demo/thank-you");
        resetForm();
      })
      .catch((error) => {
        console.log("FAILED...", error);
      });
  };

  const verifyCallback = (recaptchaToken) => {
    console.log("reCAPTCHA token:", recaptchaToken);
    return recaptchaToken;
  };

  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  // Set your event date here (example date)
  const eventDate = new Date("2024-06-26T04:00:00Z");

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     const now = new Date().getTime();
  //     const distance = eventDate - now;

  //     const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  //     const hours = Math.floor(
  //       (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  //     );
  //     const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  //     const seconds = Math.floor((distance % (1000 * 60)) / 1000);

  //     setCountdown({ days, hours, minutes, seconds });

  //     if (distance < 0) {
  //       clearInterval(interval);
  //       setCountdown({ days: 0, hours: 0, minutes: 0, seconds: 0 });
  //     }
  //   }, 1000);

  //   return () => clearInterval(interval);
  // }, []);

  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LdKfxgjAAAAAPO0jJTu6rbM0q8X8agpHUjBDsiA">
      <Helmet>
        <title>
          The 4th Annual Distressed Forum for Bank Special Assets - CyncSoftware
        </title>
      </Helmet>
      <div>
        <div className="space"></div>
        <div className="space"></div>
        <Container>
          <h1 className="text-center mt-4 rmatitle">
            The 4th Annual Distressed Forum for Bank Special Assets (West)
          </h1>
          <HrLines />
          <div className="space"></div>
          <div className="banner">
            <img src={rma} alt="Banner" />
          </div>
        </Container>
        <Container className="mt-5">
          <h1 className="text-center mt-4 rmatitle">
            July 8-9, 2024 | Waldorf Astoria Monarch Beach | Dana Point, CA
          </h1>
          <HrLines />
          <br />
          {/* Countdown Timer */}
          {/* <div className="countdown text-center mt-4">
            <div className="countdown-boxes">
              <div className="countdown-box">
                <div className="countdown-value">{countdown.days}</div>
                <div className="countdown-label">Days</div>
              </div>
              <div className="countdown-box">
                <div className="countdown-value">{countdown.hours}</div>
                <div className="countdown-label">Hours</div>
              </div>
              <div className="countdown-box">
                <div className="countdown-value">{countdown.minutes}</div>
                <div className="countdown-label">Minutes</div>
              </div>
              <div className="countdown-box">
                <div className="countdown-value">{countdown.seconds}</div>
                <div className="countdown-label">Seconds</div>
              </div>
            </div>
          </div> */}
        </Container>

        {/* Main Content and Form */}
        <Container className="mt-5">
          <Row>
            <Col md={6}>
              {/* Left Content */}
              <h3>Meet with Cync Software and Receive an Energy Drink!</h3>
              <div className="banner">
                <img src={rmaimage} alt="Banner" />
              </div>
              <p className="contentpara">
                As a <b>Diamond Sponsor</b>, the Cync Software team is excited
                to meet and partner with chapter leaders at the{" "}
                <a href="https://events.imn.org/event/bankers-west/summary">
                  The 4th Annual Distressed Forum for Bank Special Assets
                </a>{" "}
                to{" "}
                <b>
                  <i>supercharge your lending automation!</i>
                </b>{" "}
                Stop by our booth, introduce yourself, and receive a
                complimentary energy drink!* Complete the form to request an
                exclusive one-on-one conversation ahead of time with our experts
                to discover unparalleled opportunities for growth by learning
                about the latest product developments in{" "}
                <a href="/cync-loan-origination-system-los">Cync LOS</a>,
                <a href="/cync-abl"> Cync ABL</a>,{" "}
                <a href="/cync-spreading">Cync Spreading</a>, and
                <a href="/cync-factoring"> Cync Factoring</a>.{" "}
              </p>
              <div className="team-members mt-4">
                {teamMembers.map((member, index) => (
                  <div key={index} className="team-member members">
                    <img
                      src={member.imageUrl}
                      alt={member.name}
                      className="team-member-img"
                    />
                    <div className="team-member-info">
                      <div className="card-title h5">{member.name}</div>
                    </div>
                  </div>
                ))}
              </div>
              <span style={{ fontSize: "12px" }}>
                *In-person only. Offer void where prohibited by state, local, or
                institutional policy.
              </span>
            </Col>
            <Col md={6} className="form-container">
              {formSubmitted ? (
                <div className="thank-you-message">
                  <h2>Thank You!</h2>
                  <p>
                    Your message has been sent successfully. We will get back to
                    you soon.
                  </p>
                </div>
              ) : (
                <>
                  <h2>Meeting Request Form</h2>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={async (values, { resetForm }) => {
                      const token = await verifyCallback(); // Get the reCAPTCHA token
                      handleSubmit(
                        { ...values, recaptchaToken: token },
                        { resetForm }
                      );
                    }}
                  >
                    {({
                      values,
                      handleChange,
                      handleSubmit,
                      isValid,
                      dirty,
                    }) => (
                      <Form
                        onSubmit={handleSubmit}
                        className="contact-form-container"
                      >
                        <Row>
                          <Col md={6} className="contact-row">
                            <Form.Group controlId="firstName">
                              <Field
                                className="form-control contact-input"
                                type="text"
                                name="firstName"
                                placeholder="First Name*"
                                value={values.firstName}
                                onChange={handleChange}
                                required
                              />
                              <ErrorMessage
                                name="firstName"
                                component="div"
                                className="text-dangers"
                              />
                            </Form.Group>
                          </Col>
                          <Col md={6} className="contact-row">
                            <Form.Group controlId="lastName">
                              <Field
                                className="form-control contact-input"
                                type="text"
                                name="lastName"
                                placeholder="Last Name*"
                                value={values.lastName}
                                onChange={handleChange}
                                required
                              />
                              <ErrorMessage
                                name="lastName"
                                component="div"
                                className="text-dangers"
                              />
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row>
                          <Col md={6} className="contact-row">
                            <Form.Group controlId="company">
                              <Field
                                className="form-control contact-input"
                                type="text"
                                name="company"
                                placeholder="Company Name*"
                                value={values.company}
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                name="company"
                                component="div"
                                className="text-dangers"
                              />
                            </Form.Group>
                          </Col>
                          <Col md={6} className="contact-row">
                            <Form.Group controlId="jobTitle">
                              <Field
                                className="form-control contact-input"
                                type="text"
                                name="jobTitle"
                                placeholder="Job Title*"
                                value={values.jobTitle}
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                name="jobTitle"
                                component="div"
                                className="text-dangers"
                              />
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row>
                          <Col md={6} className="contact-row">
                            <Form.Group controlId="email">
                              <Field
                                className="form-control contact-input"
                                type="email"
                                name="email"
                                placeholder="Business Email*"
                                value={values.email}
                                onChange={handleChange}
                                required
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="text-dangers"
                              />
                            </Form.Group>
                          </Col>
                          <Col md={6} className="contact-row">
                            <Form.Group controlId="phone">
                              <Field
                                className="form-control contact-input"
                                type="tel"
                                name="phone"
                                placeholder="Phone Number (Optional)"
                                value={values.phone}
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                name="phone"
                                component="div"
                                className="text-dangers"
                              />
                            </Form.Group>
                          </Col>
                        </Row>

                        <Form.Group
                          controlId="products"
                          className="contact-rows"
                          style={{ position: "relative" }}
                        >
                          <Field
                            className="form-control contact-input"
                            as="select"
                            name="products"
                            value={values.products}
                            onChange={handleChange}
                            required
                            style={{ appearance: "none", paddingRight: "30px" }} // Ensure enough space for the icon
                          >
                            <option value="">Select Products</option>
                            <option value="Cync LOS">Cync LOS</option>
                            <option value="Cync ABL">Cync ABL</option>
                            <option value="Cync Factoring">
                              Cync Factoring
                            </option>
                            <option value="Cync Spreading">
                              Cync Spreading
                            </option>
                            <option value="Cync Client Portal">
                              Cync Client Portal
                            </option>
                          </Field>
                          <FontAwesomeIcon
                            icon={faCaretDown}
                            className="custom-select-icon"
                          />
                          <ErrorMessage
                            name="products"
                            component="div"
                            className="text-dangers"
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="message"
                          className="contact-rows"
                        >
                          <Field
                            className="form-control contact-input"
                            as="textarea"
                            rows={3}
                            name="message"
                            placeholder="Message"
                            value={values.message}
                            onChange={handleChange}
                          />
                          <ErrorMessage
                            name="message"
                            component="div"
                            className="text-dangers"
                          />
                        </Form.Group>

                        <Form.Group controlId="agree" className="contact-rows">
                          <Field
                            type="checkbox"
                            className="form-check-input"
                            name="agree"
                            checked={values.agree}
                            onChange={handleChange}
                            required
                          />
                          <label
                            className="form-check-label"
                            style={{ marginLeft: "10px" }}
                          >
                            I agree to receive communications from Cync Software
                          </label>
                          <ErrorMessage
                            name="agree"
                            component="div"
                            className="text-dangers"
                          />
                        </Form.Group>

                        <Button
                          className="formbtn"
                          variant="primary"
                          type="submit"
                          disabled={!(dirty && isValid)}
                        >
                          Submit
                        </Button>
                      </Form>
                    )}
                  </Formik>
                </>
              )}
            </Col>
          </Row>
        </Container>
        <Container>
          <br />
          <p className="contentpara">
            Can’t make the show? Submit a request with the form above and we’ll
            contact you directly.
          </p>
        </Container>
        <div className="space"></div>
        <div className="space"></div>
      </div>
    </GoogleReCaptchaProvider>
  );
};

export default Forum;
