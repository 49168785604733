import React, { useState, useEffect } from "react";
import "../../assets/css/CyncSpreading.css";
import { Container, Row, Col, Card, Carousel } from "react-bootstrap";
import Button from "../Button";
import HrLines from "../designs/HrLines";
import Typography from "@mui/material/Typography";
import SpreadingPopup from "../popup/LOS/SpreadingPopup.jsx";
import VideoModal from "../VideoModal";
import testimonials from "../../Data/testimonialsData.js";
import { spreadinboxes } from "../../Data/boxesData.js";
import { spreadingsteps } from "../../Data/stepsData.js";
import reverse from "../../assets/icons/reverse.png";
import { Helmet } from "react-helmet";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import SpreadingStepBoxes from "../StepBoxes/SpreadingStepBoxes.jsx";

const flipBox = (id) => {
  const clickedBox = document.getElementById(`box-${id}`);
  const allBoxes = document.querySelectorAll(".box");

  // Remove 'flipped' class from all boxes except the clicked one
  allBoxes.forEach((box) => {
    if (box !== clickedBox) {
      box.classList.remove("flipped");
    }
  });

  // Toggle 'flipped' class for the clicked box
  clickedBox.classList.toggle("flipped");
};

const CyncSpreading = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [lastActiveStep, setLastActiveStep] = useState(0);

  const handleStepClick = (index) => {
    if (activeStep === index) {
      setActiveStep(null);
    } else {
      setActiveStep(index);
      setLastActiveStep(index);
    }
  };

  const [showPopup, setShowPopup] = useState(false);

  const handleShow = () => setShowPopup(true);
  const handleClose = () => {
    setShowPopup(false);
    setOpen(false);
    setVideoUrl("");
    setCarouselPlaying(true);
  };

  const [open, setOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [carouselPlaying, setCarouselPlaying] = useState(true);

  const handleOpen = (url) => {
    setVideoUrl(url);
    setOpen(true);
    setCarouselPlaying(false);
  };

  const imageContainerStyle = {
    minHeight: "", // Set a minimum height for the image container
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "20px", // Adjust as needed
    overflow: "hidden", // Hide overflow to prevent abrupt resizing
    transition: "height 0.3s ease-in-out", // Smooth transition for height changes
    height: activeStep !== null ? "420px" : "300px", // Dynamic height based on activeStep
  };

  return (
    <div>
      <Helmet>
        <title>
          Automated Financial Spreading | Financial Spreading Software - Cync
          Software®
        </title>
        <meta
          name="description"
          content="Transform financial spreading with AI-powered automation, offering custom templates and streamlined productivity gains. Schedule a demo!"
        />
      </Helmet>
      <section className="spreadingtop-sections">
        <Container className="h-100">
          <Row className="h-100 align-items-center">
            <Col lg={9}>
              <div className="content">
                <h4
                  style={{
                    color: "#E93740",
                    fontSize: "50px",
                    fontWeight: "700",
                  }}
                >
                  Cync Spreading
                </h4>
                <h1 className="lostopheading">
                  AI-Powered Standalone Solution <br />to
                   Revolutionize Financial Spreading
                  <br /> Through Automation
                </h1>
                <p className="toppara">
                  Design custom spreading templates tailored to specific clients
                  or industries,
                  <br /> or take advantage of the built-in master templates.
                  Pivot to a highly
                  <br /> automated spreading process and reclaim hours of
                  back-office productivity.
                </p>
                <Button size="small" color="primary" onClick={handleShow}>
                  Product Demo
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <div className="space"></div>

      <section>
        <Container className="h-100">
          <h1 className="privacyheading">
            Supercharge Your Lending With Cync Spreading
          </h1>
          <HrLines />
          <br />
          <p className="contentpara">
            Manually spreading financials weighs down analysts, portfolio
            managers, and loan officers, whether they are processing,
            underwriting, monitoring, or restructuring loans. The entire process
            is not only time-consuming due to extensive data entry and analysis
            but also carries significant risks of errors and inaccuracies.
            Additionally, it lacks the scalability needed to handle large
            volumes efficiently.
          </p>
          <p className="contentpara">
            Stop the manual grunt work and say hello to Cync Spreading, a highly
            automated AI-driven solution to spread your financials in minutes
            with impressive accuracy.
          </p>
        </Container>
      </section>

      <div className="space"></div>

      <section className="benefits-with-grids">
        <div className="benefitsgrid" style={{ width: "375px" }}>
          <div className="icon">
            <img
              src={require("../../assets/icons/improvement.png")}
              alt="Icon 1"
            />
          </div>
          <div className="text">
            <h6>4X</h6>
            <h5>Back-Office Productivity Improvement</h5>
          </div>
        </div>
        <div className="benefitsgrid" style={{ width: "375px" }}>
          <div className="icon">
            <img
              src={require("../../assets/icons/development.png")}
              alt="Icon 2"
            />
          </div>
          <div className="text">
            <h6>0%</h6>
            <h5>Development to Customize Spreading Templates</h5>
          </div>
        </div>
      </section>
      <div className="space"></div>

      <section>
      <Container>
        <h1 className="privacyheading">Why Cync Spreading?</h1>
        <HrLines />
        <div className="space"></div>
        <SpreadingStepBoxes />
      </Container>
    </section>

      <div className="space"></div>

      <section>
        <Container>
          <h1 className="privacyheading">Expansive Feature Set</h1>
          <HrLines />
          <div className="space"></div>
          <Row className="box-row">
            {spreadinboxes.map((box) => (
              <Col key={box.id} md={3} sm={6} xs={12} className="box-col">
                <Card
                  className="box"
                  id={`box-${box.id}`}
                  onClick={() => flipBox(box.id)}
                >
                  <div className="front">
                    <div className="icon-top-right">
                      <img src={reverse} alt="Extra Icon" />
                    </div>
                    <img src={box.icon} alt={box.title} />
                    <br />
                    <h3>{box.title}</h3>
                  </div>
                  <div className="back">
                    <p>{box.description}</p>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <div className="space"></div>

      <section className="testimoni">
        <Carousel
          indicators={true}
          controls={false}
          interval={carouselPlaying ? 15000 : null}
          fade={true} // Add fade effect for smooth transition
          className="carousel-container"
        >
          {testimonials.map((testimonial, index) => (
            <Carousel.Item key={index} className="carousel-item">
              <img
                className="d-block w-100 carousel-image"
                src={testimonial.image}
                alt={`Slide ${index + 1}`}
              />
              <Carousel.Caption className="carousel-caption-custom">
                <h1 className="headingwhite">{testimonial.heading}</h1>
                <p className="testimobpara">{testimonial.text}</p>
                <p className="author-text">{testimonial.author}</p>
                <Button
                  size="sm"
                  variant="light"
                  onClick={() => handleOpen(testimonial.videoUrl)}
                >
                  Watch Video
                </Button>
              </Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
        <VideoModal open={open} handleClose={handleClose} videoUrl={videoUrl} />
      </section>

      <div className="space"></div>

      <section className="demo-section">
        <div className="demo-content">
          <h1>Supercharge Your Financial Spreading Now!</h1>
          <HrLines />
          <div className="space"></div>
          <Button size="small" color="primary" onClick={handleShow}>
            Product Demo
          </Button>
          <SpreadingPopup show={showPopup} handleClose={handleClose} />
        </div>
      </section>
    </div>
  );
};

export default CyncSpreading;
