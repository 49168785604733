import React from "react";
import "../../assets/css/FactoringStepBoxes.css";

const FactoringStepBoxes = () => {
  return (
    <div className="roadmap-container">
      <div className="roadmap-box left-box box1">
        <h2 style={{color:'#2971EB'}}>Rule-Based Automatic Invoice Validation</h2>
        <p>
          Stop validating each invoice manually. Set seller or debtor-specific
          rules to automatically validate invoices, with manual override
          options, to improve productivity and expedite funding.
        </p>
        <div className="arrow left-arrow"></div>
      </div>
      <div className="roadmap-box right-box box2">
        <h2 style={{color:'#2971EB'}}>Lower Risks Through Rapid Electronic Verification</h2>
        <p>
          Enable debtors to swiftly confirm invoice details and payment terms
          electronically, eliminating the need for lengthy phone calls or
          paper-based verification. Additionally, facilitate e-signing of
          documents to legally bind debtors to settle payments when invoices are
          due.
        </p>
        <div className="arrow right-arrow"></div>
      </div>
      <div className="roadmap-box left-box box3">
        <h2 style={{color:'#2971EB'}}>Streamlined Collections Through Portal and Ticklers</h2>
        <p>
          Empower collection agents with a one-stop shop that provides the
          entire invoice history, including funding approvals, comments during
          disbursement, and debtor contact information. Additionally, enable
          them to effortlessly schedule and manage follow-up actions through
          collection ticklers.
        </p>
        <div className="arrow left-arrow"></div>
      </div>
      <div className="roadmap-box right-box box4">
        <h2 style={{color:'#2971EB'}}>Flexibility to Configure Fees and Charges</h2>
        <p>
          Want to implement a tier-based, interest-based, or combined fee
          calculation method? Cync Factoring allows you to easily configure the
          factoring fee structure that matches your business requirements.
          Moreover, you can define custom charge templates to apply fees at
          specific intervals or in response to events like wire transfers or
          invoice processing.
        </p>
        <div className="arrow right-arrow"></div>
      </div>
      {/* Vertical connecting line */}
      <div className="vertical-line"></div>
    </div>
  );
};

export default FactoringStepBoxes;
