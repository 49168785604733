
// utils/urlValidator.js

const allowedPaths = ['/', '/company', '/cync-team', '/products', '/news-events', '/insights', '/contact', '/careers-culture', '/request-a-demo', '/privacy-policy', '/cync-reporting-hotline', '/responsible-disclosure-policy', '/cync-loan-origination-system-los', '/cync-abl', '/cync-factoring', '/cync-client', '/cync-spreading', '/security', '/insights/:slug', '/tag/:tag', '/contact/thank-you', '/request-a-demo/thank-you'];

export function isValidPath(path) {
  return allowedPaths.includes(path);
}


// // utils/urlValidator.js

// const allowedDomains = ['devp2.cyncsoftware.com', 'www.cyncsoftware.com'];

// export function isValidURL(url) {
//   try {
//     const urlObj = new URL(url);
//     return allowedDomains.includes(urlObj.hostname);
//   } catch (error) {
//     return false;
//   }
// }



// // validation.js
// const allowedDomains = ['devp2.cyncsoftware.com/', 'www.cyncsoftware.com/'];
// const allowedPaths = ['/company', '/cync-team' , '/products' , '/careers-culture' , '/cync-abl',
//     '/cync-factoring' , '/cync-loan-origination-system-los' , '/cync-spreading' , '/insights' , '/news-events',
//     '/request-a-demo' , '/security'
// ];

// export function isValidURL(url) {
//     try {
//         const urlObj = new URL(url);
//         return (
//             allowedDomains.includes(urlObj.hostname) &&
//             allowedPaths.some(path => urlObj.pathname.startsWith(path))
//         );
//     } catch (error) {
//         console.error('Invalid URL format: ', url);
//         return false;
//     }
// }
